import { useCustomSelector } from "store/useStore";

import "./Header.scss";

export default function Header({ className = "" }: { className?: string }) {
  const { settings } = useCustomSelector((state) => state.app);

  return (
    <div className={`header ${className}`}>
      <img src={settings?.customerSetting.logoUrl || ""} alt="" />
    </div>
  );
}
