export enum UserFlowType {
  "EMPLOYEE" = 0,
  "GUEST" = 1,
}

export interface FeatureManagementSettings {
  RfidReader: boolean;
  PaymentTerminal: boolean;
  Printer: boolean;
}

export interface AdminAppSettings {
  PaymentTerminal: { ApiKey: string };
  FeatureManagement: FeatureManagementSettings;
}
