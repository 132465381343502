import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { userActions } from "store/user";
import { menuActions } from "store/menu";

import Card from "components/ui/Card";
import Button from "components/ui/Button";

import useRfidReader from "hooks/useRfidReader";

import { Menu, UserFlowType } from "types";

import "./ActionCards.scss";

export default function ActionCards() {
  const { t } = useTranslation();

  const { settings, buttons } = useCustomSelector((state) => state.app);

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  const useEmployeeFlow = useMemo(() => !!settings?.unitSetting.accountCardEnabled, [settings]);
  const useGuestFlow = useMemo(() => !!settings?.unitSetting.paymentTerminalEnabled, [settings]);

  const { uid } = useRfidReader(useEmployeeFlow);

  useEffect(() => {
    if (!!uid) {
      dispatch(userActions.setCardNumber(uid));
      dispatch(userActions.setUserType(UserFlowType.EMPLOYEE));
      navigate("/order");
    }
  }, [uid, dispatch, navigate]);

  function handleGuestClick(button: Menu) {
    dispatch(menuActions.setMenus([button]));
    dispatch(userActions.setUserType(UserFlowType.GUEST));
    navigate("/menu");
  }

  if (useEmployeeFlow && !useGuestFlow) {
    return (
      <Card className="action-card primary-action-card">
        <p>{t("home:EmployeeCardAction")}</p>
      </Card>
    );
  }

  if (useGuestFlow && !useEmployeeFlow) {
    if (buttons.length === 0) {
      return <></>;
    }

    return (
      <Card className="action-card secondary-action-card">
        <p className="title">{t("home:GuestAction.Welcome")}</p>
        {buttons.map((button, index) => (
          <Button key={`button-${index}`} size="lg" onClick={() => handleGuestClick(button)}>
            {button.name}
          </Button>
        ))}
      </Card>
    );
  }

  return (
    <>
      <Card className="action-card primary-action-card">
        <p>{t("home:EmployeeCardAction")}</p>
      </Card>
      {buttons.length > 0 && (
        <Card className="action-card secondary-action-card">
          <p className="preface">{t("home:GuestAction.Preface")}</p>
          {buttons.map((button, index) => (
            <Button key={`button-${index}`} size="lg" onClick={() => handleGuestClick(button)}>
              {button.name}
            </Button>
          ))}
        </Card>
      )}
    </>
  );
}
