import { useCallback } from "react";

import HALService from "services/hal";
import BackendService from "services/backend";

import { customInsightsEvent } from "utils/helpers/logs";
import { getToken } from "utils/helpers/service";
import { delay } from "utils/helpers/misc";

import { AdminAppSettings, Settings } from "types";

export default function useHALSettings() {
  const updateHALSettings = useCallback(async (settings: Settings, appSettings: AdminAppSettings) => {
    // Set values in AdminAppSettings
    let updatedAppSettings = { ...appSettings };
    const unitSetting = settings.unitSetting;
    updatedAppSettings.FeatureManagement = { RfidReader: unitSetting.accountCardEnabled, PaymentTerminal: unitSetting.paymentTerminalEnabled, Printer: true };
    updatedAppSettings.PaymentTerminal = { ...updatedAppSettings.PaymentTerminal, ApiKey: unitSetting.terminalApiKey };

    let appSettingsResponse = await HALService.updateAppSettings(updatedAppSettings); // Hvis den fejler, så fortsætter vi ikke og derfor bliver hash ved med at være u-opdateret
    if (!appSettingsResponse.isSuccess()) {
      customInsightsEvent("SettingsError", {
        description: "Couldn't udate HAL app settings",
        localTime: new Date().toLocaleString(),
        token: getToken(),
      });
      return;
    }

    let customSettingsResponse = await HALService.updateCustomSettings(settings);
    if (!customSettingsResponse.isSuccess()) {
      customInsightsEvent("SettingsError", {
        description: "Couldn't udate HAL custom settings",
        localTime: new Date().toLocaleString(),
        token: getToken(),
      });
      return;
    }

    HALService.restartService();
    await delay(3000);
    return;
  }, []);

  const getAdminAppSettingsFromHAL = useCallback(async (): Promise<AdminAppSettings> => {
    let appSettingsResponse = await HALService.getAppSettings();
    if (appSettingsResponse.isSuccess()) {
      let formattedAppSettings = JSON.parse(appSettingsResponse.data);
      return formattedAppSettings as AdminAppSettings;
    }

    customInsightsEvent("SettingsError", {
      description: "Couldn't get admin settings from HAL",
      localTime: new Date().toLocaleString(),
      token: getToken(),
    });
    throw new Error("Couldn't get admin settings from HAL");
  }, []);

  const getSettingsFromAPI = useCallback(async (token?: string): Promise<Settings> => {
    let settingsResponse = await BackendService.getSettings(token);
    if (settingsResponse.isSuccess()) {
      return settingsResponse.data;
    } else {
      customInsightsEvent("SettingsError", {
        description: "Couldn't get udated settings from API",
        localTime: new Date().toLocaleString(),
        token: token || getToken(),
        errors: settingsResponse.error,
      });
      throw new Error("Couldn't get udated settings from API");
    }
  }, []);

  return { getAdminAppSettingsFromHAL, getSettingsFromAPI, updateHALSettings };
}
