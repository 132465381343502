import { useEffect } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";

import BackendService from "services/backend";

import { frontendVersion } from "utils/constants/settings";

export default function usePing() {
  const { settings, halVersion } = useCustomSelector((state) => state.app);

  const dispatch = useCustomDispatch();

  useEffect(() => {
    async function updateLatestPing() {
      if (!settings) {
        return;
      }
      const pong = await BackendService.ping({ frontendVersion, halVersion, settingHash: settings.settingHash });
      if (pong.isSuccess()) {
        dispatch(appActions.setPing(pong.data));
      } else {
        dispatch(appActions.setPing(null));
      }
    }

    updateLatestPing();
    let interval = setInterval(updateLatestPing, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [settings, halVersion, dispatch]);
}
