import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Settings, AppStore, UnitPong, Menu } from "types";

const initialState: AppStore = {
  settings: null,
  latestPing: undefined,
  isOnline: true,
  isConnectedToHAL: true,
  buttons: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<Settings>) => {
      state.settings = action.payload;
    },
    setPing: (state, action: PayloadAction<UnitPong | null>) => {
      state.latestPing = action.payload;
    },
    setHalVersion: (state, action: PayloadAction<string>) => {
      state.halVersion = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setIsConnectedToHAL: (state, action: PayloadAction<boolean>) => {
      state.isConnectedToHAL = action.payload;
    },
    setButtons: (state, action: PayloadAction<Menu[]>) => {
      state.buttons = action.payload;
    },
  },
});

export const slice = appSlice.reducer;
export const actions = appSlice.actions;
