import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCustomDispatch } from "store/useStore";
import { appActions } from "store/app";

import Input from "./Input";
import Button from "components/ui/Button";

import BackendService from "services/backend";

import useHALSettings from "hooks/useHALSettings";

import { AdminAppSettings } from "types";

import "./Credentials.scss";

export default function Credentials() {
  const { t } = useTranslation();

  const dispatch = useCustomDispatch();

  const navigate = useNavigate();

  const { getAdminAppSettingsFromHAL, updateHALSettings } = useHALSettings();

  const [tokenInput, setTokenInput] = useState("");
  const [isLoadingSettings, setIsLoadingSettings] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isHALError, setIsHALError] = useState(false);

  async function handleTokenButtonClick() {
    if (!tokenInput) {
      return;
    }
    
    setIsLoadingSettings(true);
    setIsInvalidToken(false);
    setIsHALError(false);

    let adminAppSettings: null | AdminAppSettings = null;
    try {
      adminAppSettings = await getAdminAppSettingsFromHAL();
    } catch (error) {
      console.error(error);
    }

    if (!adminAppSettings) {
      setIsHALError(true); // No HAL
      setIsLoadingSettings(false);
      return;
    }

    const settingsResponse = await BackendService.getInitialSettings(tokenInput);
    if (!settingsResponse.isSuccess()) {
      setIsLoadingSettings(false);
      setIsInvalidToken(true);
      return;
    }

    const settings = settingsResponse.data;

    await updateHALSettings(settings, adminAppSettings);

    // Then, save in store
    dispatch(appActions.setSettings(settings));
    navigate("/");
  }

  return (
    <div className="token-page">
      <div className="token-content">
        <h1>{t("token:TokenTitle")}</h1>
        <Input key="token" value={tokenInput} handleInputChange={(value) => setTokenInput(value)} handleKeyUp={(key) => key === "Enter" && handleTokenButtonClick()} />
        {isInvalidToken && <p className="token-error">{t("token:TokenError")}</p>}
        {isHALError && <p className="token-error">{t("token:ConnectionError")}</p>}
        <Button attributes={{ disabled: isLoadingSettings || !tokenInput }} onClick={handleTokenButtonClick}>
          {t("token:Continue")}
        </Button>
      </div>
    </div>
  );
}
