import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";
import { basketActions } from "store/basket";

import HALService from "services/hal";

import useProducts from "./useProducts";
import useHALSettings from "./useHALSettings";
import useHALConnection from "./useHALConnection";

import { isUnitSetupV1 } from "utils/helpers/misc";

import { UnitSetupV1 } from "types";

export default function useAppInit() {
  const { settings, isConnectedToHAL } = useCustomSelector((state) => state.app);
  const { menus } = useCustomSelector((state) => state.menu);

  const [isLoadingSettings, setIsLoadingSettings] = useState(false);

  useHALConnection();

  const { getProducts, getActiveMenus } = useProducts();
  const { getAdminAppSettingsFromHAL, getSettingsFromAPI, updateHALSettings } = useHALSettings();

  const navigate = useNavigate();
  const dispatch = useCustomDispatch();

  useEffect(() => {
    async function loadSetting() {
      setIsLoadingSettings(true);
      let customSettingsResponse = await HALService.getCustomSettings();
      if (!customSettingsResponse.isSuccess()) {
        dispatch(appActions.setIsConnectedToHAL(false));
        setIsLoadingSettings(false);
        return;
      }

      if (!!customSettingsResponse.data && customSettingsResponse.data.length > 5) {
        let settings = JSON.parse(customSettingsResponse.data);

        if (isUnitSetupV1(settings)) {
          try {
            let existingAppSettings = await getAdminAppSettingsFromHAL();
            let deprecatedSettings = settings as UnitSetupV1 & { unitToken: string }; // Custom format saved in HAL
            let token = deprecatedSettings.unitToken;
            let updatedSettings = await getSettingsFromAPI(token);
            settings = updatedSettings;

            await updateHALSettings(updatedSettings, existingAppSettings);
          } catch (error) {
            // Errors are already logged elsewhere
            console.error(error);
          }
        }

        dispatch(appActions.setSettings(settings));
        setIsLoadingSettings(false);
        return;
      }

      navigate("/credentials");
    }

    if (!settings && !isLoadingSettings && isConnectedToHAL) {
      loadSetting();
    }
  }, [settings, isLoadingSettings, isConnectedToHAL, getAdminAppSettingsFromHAL, getSettingsFromAPI, updateHALSettings, dispatch, navigate]);

  useEffect(() => {
    if (menus.length > 0) {
      let products = getProducts(getActiveMenus(menus));
      if (products.length > 0) {
        dispatch(basketActions.loadBasketItems(products));
      }
    }
  }, [menus, getProducts, getActiveMenus, dispatch]);

  return { isLoading: isLoadingSettings };
}
