import { getEnvironment } from "utils/helpers/service";

import { Settings } from "types";

export const dummySettings: Settings = {
  unitId: "test",
  customerId: "test",
  authToken: {
    token: process.env.REACT_APP_TEST_UNIT_TOKEN || "",
    createdUtc: "2024-09-09T06:43:30.0285347",
  },
  unitSetting: {
    customerId: "test",
    unitId: "test",
    terminalApiKey: "test",
    accountCardEnabled: true,
    paymentTerminalEnabled: true,
    terminalId: "test",
  },
  customerSetting: {
    logoUrl: "https://billedarkiv.blob.core.windows.net/selfservicelogo/logo.png",
  },
  settingHash: "test",
};

// No update of frontend version in ci and staging
const fallbackVersion = 1;
const stagingFrontendVersion = getEnvironment() !== "" ? fallbackVersion : null;
const productionFrontendVersion = process.env.REACT_APP_VERSION ? parseInt(process.env.REACT_APP_VERSION) : null;

export const frontendVersion = stagingFrontendVersion || productionFrontendVersion || fallbackVersion;
export const isDevFrontendVersion = frontendVersion === fallbackVersion;
