import { useTranslation } from "react-i18next";
import { useCustomSelector } from "store/useStore";

import Button from "components/ui/Button";
import Page from "components/ui/Page";

import usePaymentState from "./usePaymentState";

import { PaymentStatus, UserFlowType } from "types";

export default function PaymentErrorState({
  statusCode,
  statusName,
  onRetryClick,
  onBackClick,
}: {
  statusCode: PaymentStatus | null;
  statusName: string | null;
  onRetryClick: () => void;
  onBackClick: (isEmployeeFlow: boolean) => void;
}) {
  const { t } = useTranslation();

  const { userFlowType } = useCustomSelector((state) => state.user);

  const { isCriticalError, isFailed, isCancelled } = usePaymentState(statusCode);

  let title = t("payment:ErrorTitle");
  if (isCancelled) {
    title = t("payment:CancelledTitle");
  }
  if (isFailed) {
    title = t("payment:FailedTitle");
  }

  return (
    <Page className="payment-page">
      <div className="payment-content">
        <div>
          <h1 className="payment-error-title">{title}</h1>
          {!!statusName && isCriticalError && <p className="payment-status-name">{statusName}</p>}
        </div>
        <div className="action-buttons">
          <Button size="lg" onClick={onRetryClick}>
            {t("payment:Retry")}
          </Button>
          <Button size="lg" theme="secondary" onClick={() => onBackClick(userFlowType === UserFlowType.EMPLOYEE)}>
            {userFlowType === UserFlowType.EMPLOYEE ? t("payment:StartAgain") : t("payment:BackToMenu")}
          </Button>
        </div>
      </div>
    </Page>
  );
}
