import { useEffect, useMemo } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";

import { PongMessageType } from "types";

export default function useAlertUpdates() {
  const { latestPing, isOnline, isConnectedToHAL } = useCustomSelector((state) => state.app);

  const lastSeenUtc = useMemo(() => latestPing?.lastSeenUtc || JSON.stringify(new Date().getUTCDate()), [latestPing]);

  const dispatch = useCustomDispatch();

  const newVersionAvailable = useMemo(() => {
    if (!latestPing) {
      return false;
    }
    let pongMessages = latestPing.messages;
    if (pongMessages.find((message) => message.type === PongMessageType.NEW_VERSION_AVAILABLE)) {
      return true;
    }
    return false;
  }, [latestPing]);

  const newSettingAvailable = useMemo(() => {
    if (!latestPing) {
      return false;
    }
    let pongMessages = latestPing.messages;
    if (pongMessages.find((message) => message.type === PongMessageType.SETTING_UPDATE_AVAILABLE)) {
      return true;
    }
    return false;
  }, [latestPing]);

  // If pong hasn't responded in 30 seconds, handle as offline
  useEffect(() => {
    function handlePongTimeout() {
      dispatch(appActions.setIsOnline(false));
    }

    let timeout = setTimeout(handlePongTimeout, 30000);
    return () => {
      clearTimeout(timeout);
    };
  }, [lastSeenUtc, dispatch]);

  useEffect(() => {
    dispatch(appActions.setIsOnline(latestPing !== null));
  }, [latestPing, dispatch]);

  return { newVersionAvailable, newSettingAvailable, isOnline, isConnectedToHAL };
}
