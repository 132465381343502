import { request } from "http/client";
import { getToken, getEnvironment } from "utils/helpers/service";

import { Menu, UnitPing, UnitPong, Settings, Order } from "types";

const api = process.env.REACT_APP_BACKEND_URI || `https://api.${getEnvironment()}selfy.ninja/api`;

const BackendService = {
  ping: async (unitPing: UnitPing) => {
    return request<UnitPong>("POST", `${api}/Unit/ping`, { token: getToken(), payload: unitPing });
  },
  getInitialSettings: async (token: string) => {
    return request<Settings>("GET", `${api}/Settings/${token}`);
  },
  getSettings: async (token?: string) => {
    return request<Settings>("GET", `${api}/Settings`, { token: token || getToken() });
  },
  getButtons: async () => {
    return request<Menu[]>("GET", `${api}/Unit/button`, { token: getToken() });
  },
  getMenuByCardNumber: async (cardNumber: number) => {
    return request<Menu[]>("GET", `${api}/Unit/account/${cardNumber}/menu`, { token: getToken() });
  },
  placeOrder: async (order: Order) => {
    return request<Order>("POST", `${api}/unit/order`, { token: getToken(), payload: order });
  },
};

export default BackendService;
