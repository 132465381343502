import HttpErrorResponse from "./models/HttpErrorResponse";
import HttpSuccessResponse from "./models/HttpSuccessResponse";

type HttpResponse<T> = HttpSuccessResponse<T> | HttpErrorResponse;

async function mockRequest<T>(response: Object | string | void): Promise<HttpResponse<T>> {
  return new HttpSuccessResponse<T>(response as T);
}

async function failedMockRequest<T>(): Promise<HttpResponse<T>> {
  return new HttpErrorResponse(0, "Failed mock request");
}

export { mockRequest, failedMockRequest };
