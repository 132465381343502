import { formatPrice } from "./view";
import { BasketItem, Order, UnitSetupV1, UserFlowType } from "types";

function getFormattedDate(date: Date) {
  return `${date.toLocaleDateString("da-DK").replace(/\./g, "-")}, ${date.toLocaleTimeString()}`;
}

export function getPaymentReceiptHtml({
  paymentId,
  terminalId,
  amount,
  status,
  cardIssuer,
  cardNumber,
  externalId,
}: {
  paymentId: string;
  terminalId: string;
  amount: number;
  status: "GODKENDT" | "FEJLET";
  cardIssuer?: string;
  cardNumber?: string;
  externalId?: string;
}) {
  return `Terminal: ${terminalId}<br>
${getFormattedDate(new Date())}<br><br>
${!!cardIssuer ? `${cardIssuer}<br>` : ""}
${!!cardNumber ? `${cardNumber}<br>` : ""}
${!!externalId ? `Ref.: ${externalId}<br>` : ""}
ID: ${paymentId}<br>
KØB<br>
DKK                ${amount.toLocaleString("da", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}<br>
${status}<br>`;
}

export function getOrderReceiptHtml({ userFlowType, order, basketItems }: { userFlowType: UserFlowType | null; order: Order; basketItems: BasketItem[] }) {
  const productElements = basketItems.map((basketItem) => {
    return `${basketItem.amount} x ${basketItem.name}`;
  });
  let guestFlowSection = "";
  if (userFlowType === UserFlowType.GUEST) {
    guestFlowSection = `<br>----------<br><b>Ordreværdi: ${formatPrice(order.totalPrice)} kr</b><br>${!!order.paymentId ? `Betalingsreference: ${order.paymentId}` : ""}`;
  }

  return `<br><br><br><br><br><h1>Din bestilling</h1><br>${productElements.join("<br>")}${guestFlowSection}<br><br><h3>${getFormattedDate(new Date())}</h3><br><br><br><br>`;
}

export function isUnitSetupV1(setting: any): setting is UnitSetupV1 {
  return !setting.settingHash;
}

export function delay(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
} 