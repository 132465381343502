import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";

import appReducer from "./app";
import userReducer from "./user";
import menuReducer from "./menu";
import basketReducer from "./basket";

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  menu: menuReducer,
  basket: basketReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
