export enum OrderState {
  "INITIAL" = 0,
  "PENDING" = 1,
  "SUCCESS" = 2,
  "ERROR" = 3,
}

export enum OrderError {
  "EMPTY_PRODUCT_LIST" = 0,
  "ACCOUNT_NOT_FOUND" = 1,
}

export interface OrderLine {
  productId: string;
  amount: number;
}

export interface Order {
  cardNumber?: string | null;
  paymentId?: string | null;
  totalPrice: number;
  orderLine: OrderLine[];
}
