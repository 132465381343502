import { useCallback } from "react";

import { Menu, Product } from "types";

export const isAfterFromTime = ({ fromTime, currentHour, currentMinute }: { fromTime: Date; currentHour: number; currentMinute: number }) => {
  if (fromTime.getHours() > currentHour) {
    // Later hour than now
    return false;
  }
  if (fromTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return fromTime.getMinutes() <= currentMinute;
  }
  // Hour is before current one
  return true;
};

export const isBeforeToTime = ({ toTime, currentHour, currentMinute }: { toTime: Date; currentHour: number; currentMinute: number }) => {
  if (toTime.getHours() < currentHour) {
    // Before current hour
    return false;
  }
  if (toTime.getHours() === currentHour) {
    // Same hour, comparing minutes
    return toTime.getMinutes() > currentMinute;
  }
  // Hour is after current one
  return true;
};

export const getDateFromTime = (time: string) => {
  let hours = time.split(":")[0];
  let minutes = time.split(":")[1];

  let dateToReturn = new Date();
  dateToReturn.setHours(Number(hours));
  dateToReturn.setMinutes(Number(minutes));

  return dateToReturn;
};

export default function useProducts() {
  const getActiveMenus = useCallback((menus: Menu[]): Menu[] => {
    let available = menus;
    const now = new Date();

    // Weekday filter
    const today = now.getDay();
    available = available.filter(({ activeWeekDays = [] }) => activeWeekDays.includes(today));

    // Time of day filter
    const currentHour = now.getHours();
    const currentMinute = now.getMinutes();
    available = available.filter(({ activeFrom, activeTo }) => {
      if (!activeFrom || !activeTo) {
        return true;
      }
      let fromTimestamp = getDateFromTime(activeFrom);
      let toTimestamp = getDateFromTime(activeTo);
      return isAfterFromTime({ fromTime: fromTimestamp, currentHour, currentMinute }) && isBeforeToTime({ toTime: toTimestamp, currentHour, currentMinute });
    });

    // cnsole.log(available);
    return available;
  }, []);

  const getProducts = useCallback((menus: Menu[]): Product[] => {
    return menus.reduce((acc: Product[], curr) => acc.concat(curr.products), []);
  }, []);

  return { getProducts, getActiveMenus };
}
