import { useTranslation } from "react-i18next";

import "./FullscreenAlert.scss";

export default function MissingHALAlert() {
  const { t } = useTranslation();

  return (
    <div className="fullscreen-alert">
      <div className="alert-animation-box" />
      <div className="alert-content">
        <p className="alert-title">{t("common:MissingHAL.Title")}</p>
        <p className="alert-description">{t("common:MissingHAL.Description")}</p>
      </div>
    </div>
  );
}
