import OutdatedVersionAlert from "./OutdatedVersion";
import OutdatedSettingsAlert from "./OutdatedSettings";
import OfflineAlert from "./Offline";
import CountdownModal from "./CountdownModal";

import useAlertUpdates from "./useAlertUpdates";
import useCountdown from "./useCountdown";
import MissingHALAlert from "./MissingHAL";

import { isDevFrontendVersion } from "utils/constants/settings";

export default function Alert({ withCountdown = false }: { withCountdown?: boolean }) {
  const { newVersionAvailable, newSettingAvailable, isOnline, isConnectedToHAL } = useAlertUpdates();
  const { showCountdownModal, countdown, dismissCountdown } = useCountdown();

  if (!isConnectedToHAL) {
    return <MissingHALAlert />;
  }

  if (!isOnline) {
    return <OfflineAlert />;
  }

  if (withCountdown && showCountdownModal) {
    return <CountdownModal countdown={countdown} handleDismiss={dismissCountdown} />;
  }

  if (newVersionAvailable && !isDevFrontendVersion) {
    return <OutdatedVersionAlert />;
  }

  if (newSettingAvailable) {
    return <OutdatedSettingsAlert />;
  }

  return <></>;
}
