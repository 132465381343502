import { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/ui/Button";

import useHALSettings from "hooks/useHALSettings";

import "./PopupAlert.scss";

export default function OutdatedSettingsAlert() {
  const { t } = useTranslation();

  const [isUpdatingHAL, setIsUpdatingHAL] = useState(false);

  const { getAdminAppSettingsFromHAL, getSettingsFromAPI, updateHALSettings } = useHALSettings();

  async function handleClick() {
    setIsUpdatingHAL(true);

    try {
      let adminAppSettings = await getAdminAppSettingsFromHAL();
      let settings = await getSettingsFromAPI();

      await updateHALSettings(settings, adminAppSettings);
      window.location.reload();
    } catch (error) {
      // Errors are already logged elsewhere
      console.error(error);
    }
  }

  return (
    <div className="popup-alert">
      <p>{t("common:OutdatedSettings.Text")}</p>
      <Button attributes={{ disabled: isUpdatingHAL }} onClick={handleClick}>
        {isUpdatingHAL ? t("common:OutdatedSettings.Loading") : t("common:OutdatedSettings.Action")}
      </Button>
    </div>
  );
}
