import { useCallback, useEffect } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { appActions } from "store/app";

import HALService from "services/hal";

export default function useHALConnection() {
  const { halVersion } = useCustomSelector((state) => state.app);

  const dispatch = useCustomDispatch();

  const loadHALVersion = useCallback(async () => {
    if (!!halVersion) {
      return;
    }

    let halVersionResponse = await HALService.getVersion();
    if (halVersionResponse.isSuccess()) {
      dispatch(appActions.setIsConnectedToHAL(true));
      dispatch(appActions.setHalVersion(halVersionResponse.data));
    } else {
      dispatch(appActions.setIsConnectedToHAL(false));
    }
  }, [halVersion, dispatch]);

  useEffect(() => {
    loadHALVersion();
    let interval = setInterval(loadHALVersion, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [loadHALVersion]);
}
