import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCustomSelector } from "store/useStore";

import Page from "components/ui/Page";
import Header from "components/ui/Header";
import Basket from "components/ui/Basket";
import Product from "./Product";
import Footer from "components/ui/Footer";
import Alert from "components/ui/Alert";
import Spinner from "components/ui/Spinner";
import ProductSkeleton from "./Skeleton";

import useAppInit from "hooks/useAppInit";
import useProducts from "hooks/useProducts";

import { customInsightsEvent } from "utils/helpers/logs";

import "./Menu.scss";

export default function Menu() {
  const { t } = useTranslation();

  const { settings } = useCustomSelector((state) => state.app);
  const { isLoading: menuIsLoading, menus } = useCustomSelector((state) => state.menu);

  const { isLoading: appIsLoading } = useAppInit();
  const { getProducts, getActiveMenus } = useProducts();

  const products = useMemo(() => getProducts(getActiveMenus(menus)), [getProducts, getActiveMenus, menus]);

  useEffect(() => {
    if (!appIsLoading && products.length === 0) {
      customInsightsEvent("EmptyProductList", {
        localTime: new Date().toLocaleString(),
        unitId: settings?.unitId || "Ukendt",
        description: "No products shown for button",
        menus: JSON.stringify(menus),
      });
    }
  }, [appIsLoading, settings, menus, products.length]);

  if (appIsLoading) {
    return <Spinner />;
  }

  return (
    <Page className="menu-page">
      <Header />
      <Basket />
      <div className="page-content">
        {products.length > 0 ? (
          products.map((product) => <Product key={product.productId} product={product} />)
        ) : menuIsLoading ? (
          <>
            <ProductSkeleton />
            <ProductSkeleton />
          </>
        ) : (
          <p>{t("common:EmptyMenu")}</p>
        )}
      </div>
      <Footer />
      <Alert withCountdown />
    </Page>
  );
}
