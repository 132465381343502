import { useMemo } from "react";
import { useCustomDispatch, useCustomSelector } from "store/useStore";
import { basketActions } from "store/basket";

import Button from "components/ui/Button";
import Counter from "components/ui/Counter";

import { UserFlowType, Product as ProductType } from "types";

import "./Product.scss";

export default function Product({ product }: { product: ProductType }) {
  const { name, price, imageUrl } = product;

  const { userFlowType } = useCustomSelector((state) => state.user);
  const { basketItems } = useCustomSelector((state) => state.basket);

  const dispatch = useCustomDispatch();

  const basketItem = useMemo(() => basketItems.find((item) => item.productId === product.productId), [basketItems, product.productId]);

  return (
    <div className="product" onClick={() => dispatch(basketActions.addBasketItem(product))}>
      <img className="product-image" src={imageUrl} alt="" />
      <p className="product-name">{name}</p>
      <div className="product-footer">
        <p className={`product-price ${userFlowType === UserFlowType.GUEST ? "" : "hidden-price"}`}>{price},-</p>
        {basketItem && basketItem.amount > 0 ? (
          <Counter
            count={basketItem.amount}
            handleDecrease={(amount) => dispatch(basketActions.updateBasketItemAmount({ item: basketItem, amount }))}
            handleIncrease={(amount) => dispatch(basketActions.updateBasketItemAmount({ item: basketItem, amount }))}
          />
        ) : (
          <Button theme="secondary" className="product-add-button">
            +
          </Button>
        )}
      </div>
    </div>
  );
}
