import { request } from "http/client";
import { mockRequest, failedMockRequest } from "http/mockClient";

import { dummySettings } from "utils/constants/settings";

import { AdminAppSettings, Settings, PaymentProcessingResponse } from "types";

const api = process.env.REACT_APP_HAL_URI || `http://localhost:8888`;

const useMockHAL = !!process.env.REACT_APP_MOCK_HAL;

const HALService = {
  getVersion: async () => {
    if (useMockHAL) {
      return mockRequest<string>("Ukendt");
    }
    return request<string>("GET", `${api}/Admin/Version`);
  },
  getCustomSettings: async () => {
    if (useMockHAL) {
      return mockRequest<string>(JSON.stringify(dummySettings));
    }
    return request<string>("GET", `${api}/Admin/CustomSettings`);
  },
  updateCustomSettings: async (settings: Settings) => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<void>("POST", `${api}/Admin/CustomSettings`, { payload: settings });
  },
  getAppSettings: async () => {
    if (useMockHAL) {
      return mockRequest<string>("{}");
    }
    return request<string>("GET", `${api}/Admin/AppSettings`);
  },
  updateAppSettings: async (settings: AdminAppSettings) => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<void>("POST", `${api}/Admin/AppSettings`, { payload: settings });
  },
  restartService: async () => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<void>("POST", `${api}/Admin/ShutDown`);
  },
  getActiveRfid: async () => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<{ uidString: string; uidUInt: number; uid: string; time: string } | void>("GET", `${api}/Rfid/Read`);
  },
  startPayment: async (paymentId: string, amount: number) => {
    if (useMockHAL) {
      return failedMockRequest<PaymentProcessingResponse>();
    }
    return request<PaymentProcessingResponse>("POST", `${api}/Payment/ProcessTransaction`, {
      payload: { id: paymentId, amountCents: amount * 100 },
    });
  },
  cancelPayment: async (paymentId: string) => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<void>("POST", `${api}/Payment/CancelTransaction/${paymentId}`);
  },
  print: async (payload: string) => {
    if (useMockHAL) {
      return mockRequest<void>();
    }
    return request<void>("POST", `${api}/Print/PrintHTML`, { payload });
  },
};

export default HALService;
